/**
 * 示例-自定义组件-富文本编辑器
 * luxinwen
 * 2023-03
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content">
        <div class="page-main-content-thead">sp-editor 富文本编辑器</div>
        <div class="page-main-content-subhead">示例</div>
        <Card class="page-main-content-item">
          <sp-editor v-model="value"></sp-editor>
          <Divider orientation="left" plain>基本用法</Divider>
          <pre>&lt;sp-editor v-model="value"&gt;&lt;/sp-editor&gt;</pre>
          <p>基本用法。可以使用 v-model 双向绑定数据。</p>
          <p><span class="text-red">value</span> 只接受字符类型，为html内容。</p>
        </Card>
        <Card class="page-main-content-item">
          <sp-editor v-model="value2" disabled></sp-editor>
          <Divider orientation="left" plain>禁用状态</Divider>
          <pre>&lt;sp-editor v-model="value" disabled&gt;&lt;/sp-editor&gt;</pre>
          <p>设置属性 <span class="text-red">disabled</span>，可以禁用编辑器。</p>
        </Card>
        <div class="page-main-content-subhead">Props</div>
        <Table :columns="propsColumns" :data="propsData" border />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        params: {
          'value': {
            tips: '要编辑的内容数据，可以使用 v-model 双向绑定数据',
            type: 'String',
            default: ''
          },
          'disabled': {
            tips: '是否禁用',
            type: 'Boolean',
            default: 'false'
          },
          'height': {
            tips: '编辑器高度',
            type: 'Number',
            default: '400'
          },
          'placeholder': {
            tips: '编辑器提示文字',
            type: 'String',
            default: '请输入内容...'
          }
        },
        propsColumns: [
          {
            title: '属性',
            key: 'name',
            width: 140
          },
          {
            title: '说明',
            key: 'tips'
          },
          {
            title: '类型',
            key: 'type',
            width: 140
          },
          {
            title: '默认值',
            key: 'default',
            width: 160
          }
        ],
        value: '',
        value2: '<h1>标题标题标题</h1>'
      };
    },
    computed: {
      propsData() {
        let val = [];
        let data = this.params;
        Object.keys(data).forEach(key => {
          let item = Object.assign({}, data[key], {
            name: key
          });
          item.default = item.default || '-';
          val.push(item);
        });
        return val;
      }
    }
  };
</script>